<template>
  <auth-form-wrapper
    :title="$t('auth.register.title')"
    :subtitle="$t('auth.register.subtitle')"
    :error="error"
  >
    <!-- Registration Form -->
    <b-form 
      v-if="registerToken"
      class="auth-form mt-2" 
      @submit.prevent="handleSubmit"
    >
      <!-- Name -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.register.form.name.label') }}
        </label>
        <b-form-input
          id="register-name"
          v-model="formData.name"
          type="text"
          name="name"
          :placeholder="$t('auth.register.form.name.placeholder')"
          required
          :disabled="loading"
        />
      </div>

      <!-- Surname -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.register.form.surname.label') }}
        </label>
        <b-form-input
          id="register-surname"
          v-model="formData.surname"
          type="text"
          name="surname"
          :placeholder="$t('auth.register.form.surname.placeholder')"
          required
          :disabled="loading"
        />
      </div>

      <!-- Password -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.register.form.password.label') }}
        </label>
        <div class="input-group input-group-merge">
          <b-form-input
            id="register-password"
            v-model="formData.password"
            :type="passwordFieldType"
            name="password"
            :placeholder="$t('auth.register.form.password.placeholder')"
            class="form-control-merge"
            required
            :disabled="loading"
          />
          <div class="input-group-append">
            <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
              <feather-icon
                :icon="passwordToggleIcon"
                size="16"
                class="toggle-password-icon"
              />
            </div>
          </div>
        </div>
        <small v-if="showPasswordAlert" class="text-danger">
          {{ $t('auth.register.form.password.min-characters') }}
        </small>
      </div>

      <!-- Repeat Password -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.register.form.password-confirm.label') }}
        </label>
        <div class="input-group input-group-merge">
          <b-form-input
            id="register-password-confirm"
            v-model="formData.repeatPassword"
            :type="passwordFieldType"
            name="repeatPassword"
            :placeholder="$t('auth.register.form.password-confirm.placeholder')"
            class="form-control-merge"
            required
            :disabled="loading"
          />
          <div class="input-group-append">
            <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
              <feather-icon
                :icon="passwordToggleIcon"
                size="16"
                class="toggle-password-icon"
              />
            </div>
          </div>
        </div>
        <small v-if="showRepeatPasswordAlert" class="text-danger">
          {{ $t('auth.register.form.password.min-characters') }}
        </small>
      </div>

      <div v-if="showLegalCheckboxes">
        <div v-if="isInvited">
          <p>
            {{ $t('auth.register.form.invited-text') }}
          </p>
        </div>

          <!-- Privacy -->
          <div class="mb-1">
            <b-form-checkbox
            v-model="formData.privacyAccepted"
            name="privacy"
            :disabled="loading"
          >
            {{ $t('auth.signup.form.privacy.text1') }}
            <b-link :href="privacyPolicyUrl" target="_blank">
              {{ isNexos ? $t('auth.signup.form.privacy.link2') : $t('auth.signup.form.privacy.link') }}
            </b-link>
          </b-form-checkbox>
        </div>

        <!-- Terms -->
        <div class="mb-1">
          <b-form-checkbox
            v-model="formData.termsAccepted"
            name="terms"
            :disabled="loading"
          >
            {{ $t('auth.signup.form.terms.text1') }}
            <b-link :href="termsAndConditionsUrl" target="_blank">
              {{ $t('auth.signup.form.terms.link') }}
            </b-link>
          </b-form-checkbox>
        </div>
      </div>

      <!-- Legal -->
      <!-- <div v-if="isNexos" class="mb-1">
        {{ $t('auth.signup.legal.text1') }}
        <b-link :href="bancolombiaLegalUrl" target="_blank">
          {{ $t('auth.signup.legal.link') }}
        </b-link>
      </div> -->

      <!-- Submit -->
      <div class="auth-btn-container d-flex justify-content-center">
        <b-button
          type="submit"
          variant="primary"
          class="auth-btn auth-btn-primary mt-2 mb-2"
          :disabled="loading || isDisabled"
        >
          {{ $t('auth.register.form.action.submit') }}
        </b-button>
      </div>
    </b-form>

    <!-- Token or code expired -->
    <p class="text-center mt-2">
      {{ $t('auth.verification.expired.text') }}
      <b-link 
        class="auth-link" 
        @click.prevent="handleResendCode"
      >
        {{ $t('auth.verification.resend.link') }}
      </b-link>
      <!-- Success Message -->
      <b-alert
        v-if="showNewCodeSuccessMessage"
        show
        variant="success"
        class="mt-2"
      >
        <div class="alert-body text-center">
          <h3 class="mb-1">{{ $t('auth.verification.success.title') }}</h3>
          <p>{{ $t('auth.verification.success.message', { email: email }) }}</p>
          <p>{{ $t('common.email-attention') }}</p>
          <template v-if="showSupportEmail">
            <p>{{ $t('common.contact-support', { email: supportEmail }) }}</p>
          </template>
        </div>
      </b-alert>
    </p>

    <!-- Login Link -->
    <p class="text-center mt-2">
      <span>{{ $t('auth.register.login.text') }}</span>
      <b-link class="auth-link" :to="{ name: 'auth-login' }">
        {{ $t('auth.register.login.link') }}
      </b-link>
    </p>
  </auth-form-wrapper>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import AuthFormWrapper from '@/auth/components/AuthFormWrapper.vue'
import Vue from 'vue'
import { getDomain } from '@/store/auth/auth.store'
// import { required, email, sameAs } from '@vuelidate/validators'
// import useVuelidate from '@vuelidate/core'

export default {
  name: 'Register',

  components: {
    AuthFormWrapper
  },

  inject: ['authApiUrl'],

  mixins: [togglePasswordVisibility],

  data() {
    return {
      formData: {
        name: '',
        surname: '',
        password: '',
        repeatPassword: '',
        privacyAccepted: false,
        termsAccepted: false
      },
      loading: false,
      error: null,
      showPasswordAlert: false,
      showRepeatPasswordAlert: false,
      showNewCodeSuccessMessage: false,
      isDisabled: true,
      email: ''
    }
  },

  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.collective;
    },
    communitySlug() {
      return this.$route.params.communityId
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.mainSpace?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
    bancolombiaLegalUrl() {
      return 'https://www.bancolombia.com/personas/documentos-legales/proteccion-datos/bancolombia-sa'
    },
    registerToken() {
      return this.$route.query.token
    },
    isInvited() {
      return this.$route.query.invited
    },
    showLegalCheckboxes() {
      // show the checkboxes if the user was invited
      return this.isInvited;  //TODO: what happens if the user deletes the "invited" query in the link?
    }
  },

  watch: {
    'formData.password'(newVal) {
      this.showPasswordAlert = newVal.length > 0 && newVal.length < 6
      this.validateForm()
    },
    'formData.repeatPassword'(newVal) {
      this.showRepeatPasswordAlert = newVal.length > 0 && newVal.length < 6
      this.validateForm()
    }
  },

  created() {
    console.log('Register created')
    // Decode the email parameter and replace spaces with plus signs
    this.email = this.$route.query.email ? decodeURIComponent(this.$route.query.email).replace(/\s+/g, '+') : '';
    if(!this.registerToken){
      this.error = this.$t('auth.register.error.no-token');
    }
  },

  methods: {
    validateForm() {
      // Check password requirements
      const hasUpperCase = /[A-Z]/.test(this.formData.password);
      const hasLowerCase = /[a-z]/.test(this.formData.password);
      const hasNumber = /[0-9]/.test(this.formData.password);
      const isLongEnough = this.formData.password.length >= 6;

      // Check if all password requirements are met
      const passwordValid = hasUpperCase && hasLowerCase && hasNumber && isLongEnough;

      // Show appropriate error messages
      if (this.formData.password.length > 0) {
        if (!isLongEnough) {
          this.error = this.isNexos ? 'La contraseña no puede ser menos de 6 caracteres' : 'Password cannot be less than 6 characters in length';
        } else if (!hasUpperCase) {
          this.error = this.isNexos ? 'La contraseña debe contener al menos una letra mayúscula' : 'Password must contain at least one uppercase letter';
        } else if (!hasLowerCase) {
          this.error = this.isNexos ? 'La contraseña debe contener al menos una letra minúscula' : 'Password must contain at least one lowercase letter';
        } else if (!hasNumber) {
          this.error = this.isNexos ? 'La contraseña debe contener al menos un número' : 'Password must contain at least one number';
        } else {
          this.error = null;
        }
      }

      this.isDisabled = !(
        passwordValid &&
        this.formData.repeatPassword === this.formData.password &&
        this.formData.name &&
        this.formData.surname
      )
    },

    async handleSubmit() {
      if (this.formData.password !== this.formData.repeatPassword) {
        this.error = 'auth.register.error.password-mismatch'
        return
      }

      if(this.showLegalCheckboxes && (!this.formData.privacyAccepted || !this.formData.termsAccepted)){
        this.error = 'auth.register.error.privacy-terms-not-accepted'
        return
      }

      const endpoint = this.authApiUrl + 'register';

      try {
        this.loading = true
        this.error = null

        const response = await fetch(
          endpoint,
          {
            method: 'POST',
            body: JSON.stringify({
              ...this.formData,
              communitySlug: this.communitySlug,
              token: this.$route.query.token,
              invited: this.$route.query.invited ? Number(this.$route.query.invited) : undefined,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              defaultLanguage: this.$i18n.locale
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        const data = await response.json()

        if (!response.ok) {
          throw new Error(data.message || 'error-message.general-error')
        }

        // set the cookie for 7 days
        const date = new Date(
          new Date().setDate(new Date().getDate() + 7)
        )

        // Get parent domain (e.g. ".nectios.com") for sharing across subdomains
        const parentDomain = getDomain() ? `.${getDomain()}` : (process.env.VUE_APP_DOMAIN_1 || 'localhost')
        console.log('Setting cookie with parent domain:', parentDomain);

        // Set cookie for the parent domain to share across subdomains
        Vue.$cookies.set('accessToken', data.token, {
          path: '/',
          domain: parentDomain,
          expires: date,
          secure: window.location.protocol === 'https:',
          sameSite: 'Lax'
        });

        // Also set in localStorage as backup
        localStorage.setItem('accessToken', data.token);

        // Redirect to app
        const destinationURL = process.env.VUE_APP_APP_URL_1 + '/' + this.communitySlug;
        console.log('destinationURL', destinationURL)

        window.location.href = destinationURL;

      } catch (error) {
        console.error('Registration error:', error)
        this.error = 'auth.register.error.general'
      } finally {
        this.loading = false
      }
    },
    async handleResendCode() {
      try {
        this.loading = true
        this.error = null

        const response = await fetch(
          `${this.authApiUrl}get-new-code`,
          {
            method: 'POST',
            body: JSON.stringify({
              email: this.email,
              communitySlug: this.communitySlug
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        if (!response.ok) {
          throw new Error('auth.verification.error.resend-failed')
        }

      } catch (error) {
        console.error('Resend code error:', error)
        this.error = 'auth.verification.error.resend-failed'
      } finally {
        this.loading = false
        this.showNewCodeSuccessMessage = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-merge {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .form-control-merge {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group-append {
    display: flex;
    margin-left: -1px;

    .input-group-text {
      display: flex;
      align-items: center;
      padding: 0.571rem 1rem;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
      color: #6e6b7b;
      text-align: center;
      white-space: nowrap;
      background-color: #f8f8f8;
      border: 1px solid #d8d6de;
      border-left: 0;
      border-top-right-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;
      
      &:hover {
        background-color: #efefef;
      }
    }
  }

  .toggle-password-icon {
    cursor: pointer;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
